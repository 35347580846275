import React from "react";
import { DatabaseOutlined, MailOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const Header = () => {
  return (
    <header style={styles.header}>

      <div style={styles.headerLeft}>
        <DatabaseOutlined style={styles.icon} /> Public Data Storage
      </div>


      <div style={styles.headerRight}>
        <Tooltip title="📩 Please contact the email to get the information.">
          <span style={styles.email}>
            <MailOutlined style={styles.icon} /> crypto24support@pm.me
          </span>
        </Tooltip>
      </div>
    </header>
  );
};


const styles = {
  header: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#333",
    color: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    zIndex: 1000,
  },
  headerLeft: {
    flex: 1,
    textAlign: "left",
    display: "flex",
    alignItems: "center",
  },
  headerRight: {
    flex: 1,
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    marginRight: "10px",
    fontSize: "22px",
  },
  email: {
    cursor: "pointer", 
  },
};

export default Header;
