import React, { useState }  from 'react';

import Header from './Header';
import MessageBanner from './MessageBanner';
import CryptoDataViewer from './CryptoDataViewer';

const Home = ({}) => {


  return (
    <div className="home">
      	<Header />
      	<MessageBanner />
      	<CryptoDataViewer />
    </div>
  );
}

export default Home;