import React, { useEffect, useState } from "react";
import { Table, Input, Tooltip } from "antd";
import { 
  GlobalOutlined, 
  ShopOutlined, 
  ContainerOutlined, 
  CloudDownloadOutlined, 
  SearchOutlined
} from "@ant-design/icons"; 
import { TfiCommentAlt } from "react-icons/tfi"; // react-icons/tfi에서 Comment 아이콘을 가져옴
import "antd/dist/antd.css";

const CryptoDataViewer = () => {
  const [fileData, setFileData] = useState([]); 
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5); 
  //const backUrl = "http://nnvsec.online:5050/data";
  const backUrl = "http://j5o5y2feotmhvr7cbcp2j2ewayv5mn5zenl3joqwx67gtfchhezjznad.onion:5050/data";
  useEffect(() => {
    fetch(backUrl)
      .then((response) => response.json())
      .then((data) => setFileData(data))
      .catch((error) => console.log("failed", error));
  }, []);

  const filteredData = fileData.filter((item) =>
    Object.entries(item).some(([key, value]) => 
      key !== 'downloadLink' && String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const paginatedData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const columns = [
    {
      title: <><GlobalOutlined style={{ marginRight: "8px", color: "#4CAF50" }} /> Country</>, 
      dataIndex: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
      render: (text) => <span>{text}</span>,
      align: "center",
    },
    {
      title: <><ContainerOutlined style={{ marginRight: "8px", color: "#FF9800" }} /> Company Name</>, 
      dataIndex: "company",
      sorter: (a, b) => a.company.localeCompare(b.company),
      render: (text) => <span>{text}</span>,
      align: "center",
    },
    {
      title: <><ShopOutlined style={{ marginRight: "8px", color: "#2196F3" }} /> Domain</>, 
      dataIndex: "domain",
      sorter: (a, b) => a.domain.localeCompare(b.domain),
      render: (text) => <span>{text}</span>,
      align: "center",
    },    
    {
      title: "📦 Data Size",
      dataIndex: "size",
      render: (text) => <span>{text}</span>,
      align: "center",
    },
    {
      title: <><CloudDownloadOutlined style={{ marginRight: "8px", color: "#f44336"}} /> Data List Download</>, 
      dataIndex: "downloadLink",
      render: (text) => (
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          style={{ ...styles.downloadLink, display: "inline-block", textAlign: "center", width: "100%" }}
        >
          <CloudDownloadOutlined style={{ color: "#f44336", fontSize: "20px" }} />
        </a>
      ),
      align: "center",
    },
    {
      title: "Comment", 
      dataIndex: "comment", 
      render: (text) => (
        <Tooltip title={text} overlayStyle={{ maxWidth: "500px" }}>
          <TfiCommentAlt style={{ fontSize: "20px", color: "rgb(159 132 0)", cursor: "pointer" }} />
        </Tooltip>
      ),
      align: "center",
    }
  ];

  // rowClassName을 사용하여 홀수/짝수 행 구분
  const rowClassName = (record, index) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };

  return (
    <div style={styles.container}>

      <img src="/logo.png" alt="Crypto24 Logo" style={styles.logo} />

      <Input
        placeholder=" Please enter the search keyword..."
        prefix={<SearchOutlined style={{ color: "#aaa" }} />}
        style={styles.searchInput}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setCurrentPage(1); 
        }}
      />

      <Table
        columns={columns}
        dataSource={paginatedData} 
        rowKey="country"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: filteredData.length, 
          onChange: (page) => setCurrentPage(page), 
          showSizeChanger: false, 
		  style: { paddingRight: "20px" },
        }}
        style={styles.table}
        rowClassName={rowClassName}
      />
    </div>
  );
};

const styles = {
  container: {  
	
    maxWidth: "1000px",
    margin: "0 auto",
    marginTop: "80px",
    marginBottom: "30px",
    textAlign: "center",
    fontFamily: "'Arial', sans-serif",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "12px", 
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)", 
  },
  logo: {
    width: "100%",
    marginBottom: "20px",
  },
  searchInput: {
    width: "100%",
    marginBottom: "20px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    transition: "border 0.3s ease", 
  },
  table: {
    marginTop: "10px",
    borderRadius: "8px",
    overflow: "hidden",
    border: "1px solid #ddd", // 테이블 테두리 추가
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // 테이블에 부드러운 그림자
  },
  downloadLink: {
    color: "#007bff",
    textDecoration: "none",
    transition: "color 0.3s ease",
  },
  rowHover: {
    backgroundColor: "#f5f5f5", // 행 호버시 색상 변경
    transition: "background-color 0.3s ease",
  },
};

export default CryptoDataViewer;
