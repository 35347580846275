import React, { Component, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from './components/Home';
//import css//
import './App.css';
import 'antd/dist/antd.min.css';


//////////all routes///////
const AppRoutes = () => {
    return (
      <Routes>
          <Route path="/" element={<Home />} />          
      </Routes>
    );
}


//////////////main app//////////////
const App = () => {
 

    return (
      <BrowserRouter>
          <div className="App">                            
                <AppRoutes />             
          </div>
      </BrowserRouter>
    );
}

export default App;



