import { configureStore, createSlice } from "@reduxjs/toolkit";

// 초기 상태 정의
const initialState = {
  count: 0,
};

// Redux Slice 생성 (Reducer + Actions)
const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state) => {
      state.count += 1;
    },
    decrement: (state) => {
      state.count -= 1;
    },
  },
});

// 액션 내보내기
export const { increment, decrement } = counterSlice.actions;

// Redux Store 생성
export const store = configureStore({
  reducer: {
    counter: counterSlice.reducer,
  },
});
